export const Footer = () =>{
    return(
        <>
        <footer id="footer" className="footer">
                <div className="footer-content position-relative">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="footer-info">
                                    <h3><span style={{color:"#B8001F"}}>WHEELDRIFT </span>tires inc.</h3>
                                    <p>
                                        
                                    Located In : <a href="https://www.google.com/maps/place/Wheeldrift+Tires+%26+Alignment/@43.7321196,-79.6757697,17z/data=!3m1!4b1!4m6!3m5!1s0x882b3d23ffb14677:0x5362be46b864f3b7!8m2!3d43.7321196!4d-79.6757697!16s%2Fg%2F11lp5533jy?hl=en&entry=ttu&g_ep=EgoyMDI0MDkzMC4wIKXMDSoASAFQAw%3D%3D">30 Intermodal Dr UNIT 11, Brampton, ON L6T 5K1, Canada. </a>
                                        <br />
                                        <br/>
                                        Our Address: 30 Intermodal Dr UNIT 11, Brampton, ON L6T 5K1, Canada.
                                        <br />
                                        <br/>
                                        <strong>Phone:</strong> +16479647861
                                        <br />
                                       
                                        <br />
                                    </p>
                                    <div className="social-links d-flex mt-3">
                                        <a
                                            href="#"
                                            className="d-flex align-items-center justify-content-center"
                                        >
                                            <i className="bi bi-twitter" />
                                        </a>
                                        <a
                                            href="#"
                                            className="d-flex align-items-center justify-content-center"
                                        >
                                            <i className="bi bi-facebook" />
                                        </a>
                                        <a
                                            href="#"
                                            className="d-flex align-items-center justify-content-center"
                                        >
                                            <i className="bi bi-instagram" />
                                        </a>
                                        <a
                                            href="#"
                                            className="d-flex align-items-center justify-content-center"
                                        >
                                            <i className="bi bi-linkedin" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/* End footer info column*/}
                            <div className="col-lg-2 col-md-3 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                        <a href="/#about">About us</a>
                                    </li>
                                    <li>
                                        <a href="/#contact">Contact us</a>
                                    </li>
                                   
                                   
                                </ul>
                            </div>
                           
                          
                            {/* End footer links column*/}
                        </div>
                    </div>
                </div>
                <div className="footer-legal text-center position-relative">
                    <div className="container">
                        <div className="copyright">
                            © Copyright{" "}
                            <strong>
                                <span>WHEELDRIFT tires inc</span>
                            </strong>
                            . All Rights Reserved
                        </div>
                        <div className="credits">
                           
                            Designed by <a href="https://jatinsidana.netlify.app/">JATIN SIDANA</a>{" "}
                           
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}